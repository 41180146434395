import { Injectable } from '@angular/core';
import { filterNil } from '@project-hub/common';
import { SafeAny } from '@project-hub/types';
import { distinctUntilChanged, map, Observable, ReplaySubject, share, startWith } from 'rxjs';
import { MsalService } from '@azure/msal-angular';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  redirectUrl = '';
  userId = new ReplaySubject(1);
  userId$: Observable<string>;

  constructor(
    private readonly msalService: MsalService,
  ) {
    this.userId$ = this.createEventObservable()
      .pipe(
        map(event => event.payload?.account?.localAccountId),
        startWith(this.msalService.instance.getActiveAccount()?.localAccountId),
        filterNil(),
        distinctUntilChanged(),
        share({ connector: () => new ReplaySubject(1) }),
      );
  }

  private createEventObservable() {
    return new Observable<SafeAny>((subscriber) => {
      const id = this.msalService.instance.addEventCallback((event: SafeAny) => {
        subscriber.next(event);
      });
      return () => {
        if (id) {
          this.msalService.instance.removeEventCallback(id);
        }
      };
    });
  }
}
